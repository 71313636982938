import React, {
  useCallback, useContext, useEffect, useState,
} from "react";

import { useLazyQuery, useQuery } from "@apollo/client";
import { useLocation } from "@reach/router";
import { navigate } from "gatsby";
import { Helmet } from "react-helmet";

import { Currency, PaymentLocation, PaymentProvider } from "@/autoGeneratedGlobalTypes";
import { getExchangeRate as getExchangeRateType, getExchangeRateVariables } from "@/components/appointment/graphql/__generated__/getExchangeRate";
import { GET_EXCHANGE_RATE } from "@/components/appointment/graphql/GET_EXCHANGE_RATE";
import { getBonusFreeMinutesDiscount } from "@/components/appointment/paymentForm/utils";
import { Button, ButtonColorEnum, ButtonSizeEnum } from "@/components/common/button";
import { Dropdown, DropdownSizeEnum, DropdownValue } from "@/components/common/dropdown";
import ExchangeRate from "@/components/common/exchangeRate";
import { Icon, IconSizeEnum, IconTypeEnum } from "@/components/common/icon";
import Loader from "@/components/common/loader";
import PaymentAmountPicker from "@/components/common/PaymentAmountPicker";
import { MIN_PAYMENT_AMOUNT, paymentTexts } from "@/components/constants";
import { UserContextType } from "@/contexts/User/types";
import UserContext from "@/contexts/User/UserContext";
import { usePaymentForm } from "@/hooks/usePaymentForm";
import { setUnfinishedPaymentIdFromBankRedirect } from "@/hooks/usePaymentForm/utils";
import { PaymentSuccessDataType, usePayWithSavedCard } from "@/hooks/usePayWithSavedCard";
import { MARKETING_PAYMENT_SUCCESS } from "@/marketing/constants";
import { isBrowser } from "@/utils/env";
import { currencyToString } from "@/utils/globalTypesUtils";
import { priceToString } from "@/utils/numberUtils";
import { cleanupFromTags } from "@/utils/stringUtils";

import {
  CLOUD_PAYMENT_CARD_VALUE,
  MAX_DIGITS_AMOUNT,
  PROFILE_PAYMENT_INITIAL_AMOUNT,
  SOFTLINE_PAYMENT_CARD_VALUE,
  SOM_PAYMENT_CARD_VALUE,
} from "./constants";
import { getPaymentPageData } from "./graphql/__generated__/getPaymentPageData";
import { GET_PAYMENT_PAGE_DATA } from "./graphql/GET_PAYMENT_PAGE_DATA";
import { cardToJsx, isAmountValid } from "./utils";

import "./styles.scss";

const PaymentForm = () => {
  const {
    isUserLoggedIn, setUnfinishedPaymentId,
    setSavedCardPaymentResultPath, savedCardPaymentResultPath, userID,
  } = useContext<UserContextType>(UserContext);
  const { data, error, loading } = useQuery<getPaymentPageData>(GET_PAYMENT_PAGE_DATA);
  const [
    getExchangeRateQuery,
    { data: dataExchangeRate },
  ] = useLazyQuery<getExchangeRateType, getExchangeRateVariables>(GET_EXCHANGE_RATE);
  const [isAmountTouched, setIsAmountTouched] = useState<boolean>(false);
  const [selectedAmount, setSelectedAmount] = useState<number>(PROFILE_PAYMENT_INITIAL_AMOUNT);
  const [cards, setCards] = useState<DropdownValue[]>([]);
  const [selectedCard, setSelectedCard] = useState<DropdownValue>(CLOUD_PAYMENT_CARD_VALUE);
  const [rememberCard, setRememberCard] = useState<boolean>(true);
  const redirectPathAfterPayment = useLocation().href;
  const [loadingForForeignPayment, setLoadingForForeignPayment] = useState(false);

  const isSelectedSomPayment = selectedCard.value === SOM_PAYMENT_CARD_VALUE.value;
  const isSelectedSoftlinePayment = selectedCard.value === SOFTLINE_PAYMENT_CARD_VALUE.value;
  const isSelectedCloudPayemnt = selectedCard.value === CLOUD_PAYMENT_CARD_VALUE.value;

  const paymentSuccessCallback = useCallback(() => {
    navigate(`/profile/balance/?${MARKETING_PAYMENT_SUCCESS}=1`, {
      state: { showPaymentAnimation: true, isSuccessPayment: true },
    });
  }, []);

  const paymentFailCallback = useCallback(() => {
    navigate("/profile/payment", { state: { isSuccessPayment: false } });
  }, []);

  useEffect(() => {
    if (redirectPathAfterPayment.includes("request=success")) {
      setUnfinishedPaymentId(setUnfinishedPaymentIdFromBankRedirect(redirectPathAfterPayment));
      navigate(`/profile/balance/?${MARKETING_PAYMENT_SUCCESS}=1`, {
        state: { showPaymentAnimation: true },
      });
    }

    if (redirectPathAfterPayment.includes("request=fail")) {
      setUnfinishedPaymentId(setUnfinishedPaymentIdFromBankRedirect(redirectPathAfterPayment));
      navigate("/profile/payment");
    }
  }, [redirectPathAfterPayment, setUnfinishedPaymentId]);

  const {
    openPaymentForm,
  } = usePaymentForm({
    onSuccess: paymentSuccessCallback,
    onFail: paymentFailCallback,
  });

  const paymentWithSavedCardSuccessCallback = useCallback((dataSuccess: PaymentSuccessDataType) => {
    setSavedCardPaymentResultPath(`/profile/payment-result/?success=1&payment_id=${dataSuccess.makePaymentWithSavedCard.id}&${MARKETING_PAYMENT_SUCCESS}=1`);
  }, []);

  const paymentWithSavedCardFailCallback = useCallback((dataFail: PaymentSuccessDataType) => {
    setSavedCardPaymentResultPath(`/profile/payment-result/?failure=1&payment_id=${dataFail.makePaymentWithSavedCard.id}`);
  }, []);

  const { payWithSavedCard, loading: payWithSavedCardLoading } = usePayWithSavedCard({
    onSuccess: paymentWithSavedCardSuccessCallback,
    onFail: paymentWithSavedCardFailCallback,
  });

  useEffect(() => {
    if (data && !error && !loading) {
      const joinSoftLinePaymentFlag = process.env.GATSBY_ADD_SOFTLINE_PAYMENT === "true";
      const userCards: DropdownValue[] = data.getUserCards?.map((c) =>
        cardToJsx(c)) ?? [];
      userCards.push(CLOUD_PAYMENT_CARD_VALUE, SOM_PAYMENT_CARD_VALUE);

      if (joinSoftLinePaymentFlag) {
        userCards.push(SOFTLINE_PAYMENT_CARD_VALUE);
      }

      setCards(userCards);
      const defaultCard = data.getUserCards.find((card) =>
        card.isDefault);

      if (defaultCard) {
        setSelectedCard(cardToJsx(defaultCard));
      } else {
        setSelectedCard(CLOUD_PAYMENT_CARD_VALUE);
      }
    }
  }, [data, error, loading]);

  useEffect(() => {
    if (isSelectedSomPayment || isSelectedSoftlinePayment) {
      const paymentProviderType = isSelectedSomPayment
        ? PaymentProvider.SomPayments : PaymentProvider.SoftlinePayments;

      getExchangeRateQuery({
        variables: {
          input: {
            curFrom: isSelectedSomPayment ? Currency.USD : Currency.EUR,
            curTo: Currency.RUB,
            paymentProvider: paymentProviderType,
          },
        },
      });
    }
  }, [getExchangeRateQuery, isSelectedSoftlinePayment, isSelectedSomPayment, selectedCard]);

  const handlePaymentClick = () => {
    switch (true) {
      case isSelectedCloudPayemnt:
        openPaymentForm(
          selectedAmount,
          rememberCard,
          PaymentProvider.CloudPayments,
          "",
          PaymentLocation.profile,
          userID!,
        );
        break;
      case isSelectedSomPayment:
        setLoadingForForeignPayment(true);
        openPaymentForm(
          selectedAmount,
          rememberCard,
          PaymentProvider.SomPayments,
          redirectPathAfterPayment,
          PaymentLocation.profile,
          userID!,
        );
        break;
      case isSelectedSoftlinePayment:
        setLoadingForForeignPayment(true);
        openPaymentForm(
          selectedAmount,
          rememberCard,
          PaymentProvider.SoftlinePayments,
          redirectPathAfterPayment,
          PaymentLocation.profile,
          userID!,
        );
        break;
      default:
        payWithSavedCard(selectedAmount, Number(selectedCard.value), PaymentLocation.profile, userID!);
    }
  };

  if (!isUserLoggedIn) {
    if (isBrowser()) {
      navigate("/");
    }
  }

  if (loading) {
    return <Loader />;
  }

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const numberValue = parseInt(e.target.value, 10);
    // user may enter zeros before the value, and we preserve them,
    // but we make sure the value is valid
    setSelectedAmount((oldValue) =>
      (numberValue.toString().length <= MAX_DIGITS_AMOUNT ? numberValue : oldValue));
  };

  const handleCardChange = (value: DropdownValue) => {
    setSelectedCard(value);
  };

  const handleBulletClick = (amount: number) => {
    setSelectedAmount(amount);
  };

  const isBonusAmount = getBonusFreeMinutesDiscount(selectedAmount) !== 0;

  return (
    <>
      <Helmet>
        <html className="html--full-height" lang="ru" />
        <body className="footer--short header--short" />
      </Helmet>
      <div className="profile-payment__container">
        <div className="profile-payment">
          <h2 className="profile-payment__header">Пополнение баланса</h2>

          {data?.getMyProfile && (
            <div className="profile-payment__card">
              <div>Ваш баланс</div>
              <h4 className="profile-payment__balance">
                <span
                  className="profile-payment__amount"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: cleanupFromTags(
                      priceToString(data.getMyProfile.balance.amount).replace(" ", "&nbsp;"),
                    ),
                  }}
                />
                &nbsp;
                <span className="profile-payment__currency">
                  {currencyToString(data.getMyProfile.balance.currency)}
                </span>
              </h4>
            </div>
          )}

          <form
            className="profile-payment__form"
            onSubmit={(e) =>
              e.preventDefault()}
          >
            <div className="profile-payment__field-group">
              <h4 className="profile-payment__label">
                <label htmlFor="amount">Сумма пополнения</label>
              </h4>
              <input
                id="amount"
                name="amount"
                type="number"
                pattern="\d*"
                inputMode="numeric"
                value={Number.isNaN(selectedAmount) ? "" : selectedAmount}
                onChange={handleAmountChange}
                onBlur={() =>
                  setIsAmountTouched(true)}
                className={`profile-payment__input amount${
                  isAmountTouched && !isAmountValid(selectedAmount) ? " error" : ""
                }`}
                aria-invalid={isAmountTouched && !isAmountValid(selectedAmount) ? "true" : "false"}
              />
              {isAmountTouched && !isAmountValid(selectedAmount) && (
                <div className="auth__error" role="alert">
                  {`Минимальная сумма пополнения — ${MIN_PAYMENT_AMOUNT} ₽`}
                </div>
              )}
            </div>
            <PaymentAmountPicker
              handleBulletClick={handleBulletClick}
              isBonusAmount={isBonusAmount}
            />
            <div className="profile-payment__field-group">
              <h4 className="profile-payment__label">
                <label htmlFor="card">Способ пополнения</label>
              </h4>
              <Dropdown
                id="card"
                name="card"
                value={selectedCard}
                options={cards}
                onChange={handleCardChange}
                size={DropdownSizeEnum.Middle}
                className="profile-payment__input"
              />
              {(isSelectedSomPayment || isSelectedSoftlinePayment)
              && dataExchangeRate
              && selectedAmount
              && (
              <ExchangeRate
                amount={selectedAmount}
                rate={dataExchangeRate.getExchangeRate}
                paymentUSD={isSelectedSomPayment}
              />
              )}
            </div>
            <Button
              text="Пополнить"
              subtext={selectedAmount ? `на ${priceToString(selectedAmount)} ₽` : ""}
              size={ButtonSizeEnum.Large}
              color={ButtonColorEnum.Dark}
              className="profile-payment__submit"
              isLoading={payWithSavedCardLoading
                || loadingForForeignPayment
                || !!savedCardPaymentResultPath}
              disabled={!isAmountValid(selectedAmount)}
              onClick={handlePaymentClick}
            />
            {(isSelectedCloudPayemnt || isSelectedSomPayment || isSelectedSoftlinePayment) && (
              <div className="profile-payment__remember-block">
                <div className="profile-payment__checkbox-group">
                  <input
                    type="checkbox"
                    checked={rememberCard}
                    onChange={() =>
                      setRememberCard((prevVal) =>
                        !prevVal)}
                  />
                  <span className="profile-payment__checkbox-label">
                    {paymentTexts.rememberText}
                  </span>
                </div>
                <div className="profile-payment__message">{paymentTexts.message}</div>
                <div className="profile-payment__message">
                  <Icon type={IconTypeEnum.Shield} size={IconSizeEnum.Size28} />
                  {paymentTexts.protectionInfo}
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default PaymentForm;
