import { gql } from "@apollo/client";

export const GET_PAYMENT_PAGE_DATA = gql`
  query getPaymentPageData {
    getMyProfile {
      balance {
        amount
        currency
      }
    }
    getUserCards {
      id
      number
      type
      expiryMonth
      expiryYear
      isDefault
    }
    getBillingEvents {
      edges {
        node {
          payment {
            amount
          }
        }
      }
    }
  }
`;
