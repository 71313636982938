import React from "react";

import { IconSizeEnum } from "@/components/common/icon";
import { MIN_PAYMENT_AMOUNT } from "@/components/constants";
import { cardTypeToString } from "@/utils/globalTypesUtils";

import CardIcon from "../balance/paymentMethods/cardIcon";

import { getPaymentPageData_getUserCards } from "./graphql/__generated__/getPaymentPageData";

export const cardToJsx = (card: getPaymentPageData_getUserCards) =>
  ({
    value: card.id.toString(),
    label: (
      <div className="profile-payment__option">
        <CardIcon type={card.type} size={IconSizeEnum.Size32} />
        {`${cardTypeToString(card.type)} • ${card.number}`}
      </div>
    ),
  });

export const isAmountValid = (amount: number) =>
  !Number.isNaN(amount) && amount >= MIN_PAYMENT_AMOUNT;
