import React from "react";

import PaymentForm from "@/components/profile/paymentForm";
import { isBrowser } from "@/utils/env";

import "@/pages/common.scss";

const PaymentPage = () => {
  if (!isBrowser()) {
    return null;
  }

  return <PaymentForm />;
};

export default PaymentPage;
